import * as React from "react";

import Layout from '../components/layout';

import { StaticImage } from "gatsby-plugin-image";
import BottomSpace from "../components/bottomSpace";
import ClassNames from "../utils/classNames";
import {
    content,
    dogTray,
    dogTrayInfo,
    info,
    right,
    sectionTitle
} from './till-minne.module.scss';

import Zoom from '../components/Zoom';

const DogsPage = () => {
    return (
        <Layout pageTitle="Till minne" largeHero={false}>
            <div style={{ height: "100%" }} className={content}>
                <div style={{ marginLeft: "5%", marginRight: "5%" }}>
                    <h1 className={sectionTitle}>Till minne av Juni 2018-2021 🌹</h1>

                    <div className={dogTray} style={{ marginTop: "48px" }}>
                        <Zoom>
                            <StaticImage loading="eager" src={'../images/juni2.jpg'} alt={'Juni, Mellanpudel'} />
                        </Zoom>

                        <div className={ClassNames(info, right)}>
                            <h3 style={{ color: "#484848" }}>Dreams In Red Chip N Chili (SE43444/2018)</h3>
                            <p className={dogTrayInfo}>Juni var en underbar karaktär som gjorde ett intryck på alla, oerhört intelligent och stark. Hon sådde på sätt och vis fröet till vår lilla kennel. Juni insjuknade tyvärr hastigt hösten 2021 i en okänd sjukdom som veterinärerna inte kunde ge någon förklaring till, efter en månads kämpande med mängder av verkningslösa behandlingar tog vi det tuffa beslutet att låta Juni somna in. <br></br><br></br>Vila i frid Juni. 🌹</p>
                        </div>
                    </div>

                </div>
            </div>

            <BottomSpace />
        </Layout>
    )
}

export default DogsPage